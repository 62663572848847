<template>
  <div>
    <div class="pageTitle">跨案場分析</div>
    <div class="borderColor borderColorBackground mb-3">
      <div class="d-flex align-items-center flex-wrap m-2">
        <button
          class="datepickerButton"
          :class="{ 'date-active': dateTypeActive === 'hour' }"
          @click="changeDateType('date', 'yyyy-MM-dd', 'hour')"
        >
          時
        </button>
        <button
          class="datepickerButton"
          :class="{ 'date-active': dateTypeActive === 'date' }"
          @click="changeDateType('date', 'yyyy-MM-dd', 'date')"
        >
          日
        </button>
        <button
          class="datepickerButton"
          :class="{ 'date-active': dateTypeActive === 'month' }"
          @click="changeDateType('month', 'yyyy-MM', 'month')"
        >
          月
        </button>
        <button
          class="datepickerButton mr-4"
          :class="{ 'date-active': dateTypeActive === 'year' }"
          @click="changeDateType('year', 'yyyy', 'year')"
        >
          年
        </button>
        <div class="my-2 mr-4">
          起始時間 :&nbsp;
          <date-picker
            v-model="beginDate"
            placeholder="起始時間"
            :type="dateType"
            valueType="format"
            style="width: 150px"
            :disabled-date="disabledDateAfterToday"
          />
        </div>
        <div class="my-2 mr-4" v-if="dateTypeActive !== 'hour'">
          結束時間 :&nbsp;
          <date-picker
            v-model="endDate"
            placeholder="結束時間"
            :type="dateType"
            valueType="format"
            style="width: 150px"
            :disabled-date="disabledDateAfterToday"
          />
        </div>
        <button class="datepickerButton" @click="multipleDataPush">
          新增一列
        </button>
      </div>
      <div class="otherTableStyle bg-white">
        <table class="w-100 text-center">
          <tr>
            <th>序號</th>
            <th>案場</th>
            <th>曲線顏色</th>
            <th>功能</th>
          </tr>
          <tr v-for="(i, idx) in multipleDataArr" :key="idx">
            <td>{{ idx + 1 }}</td>
            <td>
              <select v-model="i.county" class="m-5px" @change="i.factory = ''">
                <option value="null">縣市</option>
                <option :value="x" v-for="x in countyArr" :key="x">
                  {{ x }}
                </option>
              </select>
              <select
                v-model="i.factory"
                class="m-5px"
                style="min-width: 200px"
              >
                <option value="">案場</option>
                <option
                  :value="x.factory"
                  v-for="(x, xidx) in filterFactoryArr(i.county)"
                  :key="xidx"
                >
                  {{ x.factory }}
                </option>
              </select>
            </td>
            <td>
              <input type="color" v-model="i.color" />
            </td>
            <td>
              <button
                class="deleteButton m-5px"
                @click="multipleDataSplice(idx)"
              >
                刪除
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="bg-white text-center">
        <button class="confirmButton m-2" @click="confirmData">確認</button>
      </div>
    </div>

    <div class="borderColor borderColorBackground mb-4">
      <div class="d-flex align-items-center m-3">
        <b-form-checkbox-group
          v-model="contextDataClass"
          :options="contextArr"
          switches
        />
        <button
          class="confirmButton ml-auto"
          style="width: 120px"
          @click="downloadAnalysis"
        >
          資料下載
        </button>
      </div>
      <highcharts ref="chart" :options="multipleChart" class="bg-white pt-3" />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapState, mapActions } from 'vuex'
import {
  getMultipleHourDataApi,
  getMultipleDayDataApi,
  getMultipleMonthDataApi,
  getMultipleYearDataApi
} from '../api'
import { dbDataHandle } from '../store/function/common/index'
import downloadCsv from 'download-csv'
import { disabledDateAfterToday } from '@/utils/datetime'
export default {
  name: 'MultipleFactoryAnalysis',
  computed: {
    ...mapState(['factoryInfo'])
  },
  data () {
    return {
      beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
      endDate: DateTime.local().toFormat('yyyy-MM-dd'),
      dateType: 'date',
      dateTypeActive: 'hour',
      contextDataClass: [
        'PR值-pr',
        '環境溫度-ENVTemp',
        '等效日照時數(h)-IRR',
        '峰瓩值(kWh/kWp)-acp'
      ],
      dataClass: ['發電量', '日照量', '實際累積發電量'],
      countyArr: [],
      factoryArr: [],
      multipleChartDataArr: [],
      contextArr: [
        {
          value: 'PR值-pr',
          text: 'PR值'
        },
        {
          value: '環境溫度-ENVTemp',
          text: '環境溫度'
        },
        {
          value: '等效日照時數(h)-IRR',
          text: '等效日照時數(h)'
        },
        {
          value: '峰瓩值(kWh/kWp)-acp',
          text: '峰瓩值(kWh/kWp)'
        }
      ],
      multipleDataArr: [
        {
          county: '高雄市',
          factory: '鳳山圳滯洪池',
          color: '#f6b73c'
        },
        {
          county: 'null',
          factory: '',
          color: '#000000'
        }
      ],
      multipleChart: {
        chart: {
          height: 350,
          backgroundColor: null,
          events: {
            load () {
              this.showLoading('尚未有資料，請選擇案場')
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
          floating: false
        },
        yAxis: [],
        tooltip: {
          shared: true
        },
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  y: 0
                }
              }
            }
          ]
        }
      },
      downloadArrayForExcel: [] // 另存 excel 用 (copy multipleChart.series)
    }
  },
  methods: {
    ...mapActions(['asyncInfo']),
    disabledDateAfterToday,
    multipleDataPush () {
      this.multipleDataArr.push({
        county: 'null',
        factory: '',
        context: {
          values: 'pr',
          text: 'PR值'
        },
        color: '#000000'
      })
    },
    multipleDataSplice (idx) {
      this.multipleDataArr.splice(idx, 1)
    },
    changeDateType (type, format, active) {
      this.dateTypeActive = active
      this.dateType = type
      this.beginDate = DateTime.local().toFormat(`${format}`)
      this.endDate = DateTime.local().toFormat(`${format}`)
    },
    buildOptionArr () {
      this.countyArr = []
      this.factoryArr = this.factoryInfo
        .filter((item) => !item.isDemoSite)
        .map((x) => {
          if (!this.countyArr.includes(x.informations.county)) {
            this.countyArr.push(x.informations.county)
          }
          return {
            county: x.informations.county,
            factory: x.factory,
            factoryId: x.factoryId
          }
        })
    },
    filterFactoryArr (county) {
      if (county === 'null') return this.factoryArr
      return this.factoryArr.filter((x) => x.county === county)
    },
    confirmData () {
      if (
        this.multipleDataArr.every((x) =>
          Object.values(x).every((y) => y !== '')
        )
      ) {
        if (this.dateTypeActive === 'hour') this.getMultipleHourData()
        if (this.dateTypeActive === 'date') this.getMultipleDayData()
        if (this.dateTypeActive === 'month') this.getMultipleMonthData()
        if (this.dateTypeActive === 'year') this.getMultipleYearData()
      } else {
        this.$swal('請正確選擇')
      }
    },
    buildChart () {
      this.multipleChart.yAxis = []
      this.multipleChart.series = []
      this.downloadArrayForExcel = []

      this.buildYaxis()
      this.defaultXaxis()

      this.multipleChartDataArr.forEach((x) => {
        x.forEach((y) => {
          this.contextDataClass.forEach((s) => {
            const index = this.multipleChart.xAxis.categories.findIndex(
              (q) => q === y.time
            )
            if (index >= 0) {
              // chart 顯示調整過數值
              this.multipleChart.series.find(
                (z) =>
                  z.context === s.split('-')[1] &&
                  z.name.split('_')[0] === y.siteName
              ).data[index] = dbDataHandle(y[s.split('-')[1]])

              // excel 存 rawdata
              this.downloadArrayForExcel.find(
                (z) =>
                  z.context === s.split('-')[1] &&
                  z.name.split('_')[0] === y.siteName
              ).data[index] = y[s.split('-')[1]]
            }
          })
        })
      })
      this.hideHighChartLoading()
    },
    hideHighChartLoading () {
      this.$nextTick(function () {
        if (this.$refs && this.$refs.chart) {
          this.$refs.chart.chart.hideLoading()
          this.multipleChart.chart.events = {}
        }
      })
    },
    buildYaxis () {
      for (let i = 0; i < this.multipleDataArr.length; i++) {
        for (let j = 0; j < this.contextDataClass.length; j++) {
          if (i === 0) {
            this.multipleChart.yAxis.push({
              title: {
                text: `${this.contextDataClass[j].split('-')[0]}`
              },
              opposite: j % 2 === 0
            })
          }
          const obj = {
            type: 'line',
            color:
              this.multipleDataArr[i].color === ''
                ? '#000000'
                : this.multipleDataArr[i].color,
            name: `${this.multipleDataArr[i].factory}_${
              this.contextDataClass[j].split('-')[0]
            }`,
            context: this.contextDataClass[j].split('-')[1],
            data: [],
            yAxis: this.multipleChart.yAxis.findIndex(
              (z) => z.title.text === this.contextDataClass[j].split('-')[0]
            )
          }
          this.multipleChart.series.push(obj)

          // 下載 excel 用，不 refer 同個 obj
          const objForExcel = {
            name: `${this.multipleDataArr[i].factory}_${
              this.contextDataClass[j].split('-')[0]
            }`,
            context: this.contextDataClass[j].split('-')[1],
            data: []
          }

          this.downloadArrayForExcel.push(objForExcel)
        }
      }
    },
    defaultXaxis () {
      this.multipleChart.xAxis.categories = []
      if (this.dateTypeActive === 'hour') {
        for (let i = 0; i <= 24; i++) {
          this.multipleChart.xAxis.categories.push(`0${i}`.slice(-2) + ':00')
        }
        this.multipleChart.series.forEach((x) => {
          for (let i = 0; i < 24; i++) {
            x.data.push(null)
          }
        })

        // 下載 excel 用
        this.downloadArrayForExcel.forEach((x) => {
          for (let i = 0; i < 24; i++) {
            x.data.push(null)
          }
        })
      } else {
        const beginDate = this.beginDate
        const endDate = this.endDate
        let diff
        if (this.dateTypeActive === 'date') {
          diff = DateTime.fromISO(endDate).diff(
            DateTime.fromISO(beginDate),
            'days'
          ).days
        }
        if (this.dateTypeActive === 'month') {
          diff = DateTime.fromISO(endDate).diff(
            DateTime.fromISO(beginDate),
            'months'
          ).months
        }
        if (this.dateTypeActive === 'year') {
          diff = DateTime.fromISO(endDate).diff(
            DateTime.fromISO(beginDate),
            'years'
          ).years
        }

        for (let i = 0; i <= diff; i++) {
          if (this.dateTypeActive === 'date') {
            this.multipleChart.xAxis.categories.push(
              DateTime.fromISO(beginDate)
                .plus({ days: i })
                .toFormat('yyyy-MM-dd')
            )
          }
          if (this.dateTypeActive === 'month') {
            this.multipleChart.xAxis.categories.push(
              DateTime.fromISO(beginDate)
                .plus({ months: i })
                .toFormat('yyyy-MM')
            )
          }
          if (this.dateTypeActive === 'year') {
            this.multipleChart.xAxis.categories.push(
              DateTime.fromISO(beginDate).plus({ years: i }).toFormat('yyyy')
            )
          }
        }
        this.multipleChart.series.forEach((x) => {
          for (let i = 0; i < diff; i++) {
            x.data.push(null)
          }
        })

        // 下載 excel 用
        this.downloadArrayForExcel.forEach((x) => {
          for (let i = 0; i < diff; i++) {
            x.data.push(null)
          }
        })
      }
    },
    async getMultipleHourData () {
      const data = []
      this.multipleDataArr.forEach((x) => {
        const obj = {
          fId: this.factoryInfo.find((y) => y.factory === x.factory).factoryId,
          siteName: x.factory,
          todayDate: this.beginDate
        }
        data.push(obj)
      })
      const hourData = await getMultipleHourDataApi(data)
      if (hourData?.data?.success) {
        this.multipleChartDataArr = hourData.data.data
        this.buildChart()
      }
    },
    async getMultipleDayData () {
      const data = []
      this.multipleDataArr.forEach((x) => {
        const obj = {
          fId: this.factoryInfo.find((y) => y.factory === x.factory).factoryId,
          siteName: x.factory,
          beginDate: this.beginDate,
          endDate: this.endDate
        }
        data.push(obj)
      })
      const dayData = await getMultipleDayDataApi(data)
      if (dayData?.data?.success) {
        this.multipleChartDataArr = dayData.data.data
        this.buildChart()
      }
    },
    async getMultipleMonthData () {
      const data = []
      this.multipleDataArr.forEach((x) => {
        const obj = {
          fId: this.factoryInfo.find((y) => y.factory === x.factory).factoryId,
          siteName: x.factory,
          beginDate: this.beginDate,
          endDate: this.endDate
        }
        data.push(obj)
      })
      const monthData = await getMultipleMonthDataApi(data)
      if (monthData?.data?.success) {
        this.multipleChartDataArr = monthData.data.data
        this.buildChart()
      }
    },
    async getMultipleYearData () {
      const data = []
      this.multipleDataArr.forEach((x) => {
        const obj = {
          fId: this.factoryInfo.find((y) => y.factory === x.factory).factoryId,
          siteName: x.factory,
          beginDate: this.beginDate,
          endDate: this.endDate
        }
        data.push(obj)
      })
      const yearData = await getMultipleYearDataApi(data)
      if (yearData?.data?.success) {
        this.multipleChartDataArr = yearData.data.data
        this.buildChart()
      }
    },
    downloadAnalysis () {
      const downloadArr = []
      let fields = ['name']
      fields = fields.concat(this.multipleChart.xAxis.categories)
      this.multipleChart.series.forEach((x) => {
        downloadArr.push({ name: x.name })
      })
      this.multipleChart.xAxis.categories.forEach((x, xindex) => {
        downloadArr.forEach((y, yindex) => {
          y[`${x}`] = this.downloadArrayForExcel[yindex].data[xindex]
        })
      })
      downloadCsv(downloadArr, fields)
    }
  },
  watch: {
    contextDataClass: function (oldValue, newValue) {
      if (oldValue !== newValue && this.multipleChartDataArr.length !== 0) {
        this.buildChart()
      }
    }
  },
  async mounted () {
    if (!this.factoryInfo.length) await this.asyncInfo()
    this.buildOptionArr()
  }
}
</script>
